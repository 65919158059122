@import "../global";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap Overrides
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Pages
@import 'pages/dashboard';

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

p,
h1,
h2,
h3,
h4,
h5 {
    margin: 0px;
}

h5 {
    padding-top: 20px;
    font-size: 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
}

button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.h-250 {
    height: 250px;
}

.h-185 {
    height: 185px;
}


.color-black {
    color: $color-black;
}

.no-padding {
    padding: 0px !important;
}

.padding-t-15 {
    padding-top: 15px;
}

.padding-b-15 {
    padding-bottom: 15px;
}

.padding-b-20 {
    padding-bottom: 20px;
}

.padding-t-25 {
    padding-top: 25px;
}

.no-margin-h {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.t-center {
    text-align: center;
}

.h-90 {
    height: 90% !important;
}

.pb-150 {
    padding-bottom: 150px;
}

.no-padding-h {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.banner-home {
    position: relative;
    height: 255px;
    width: 100%;

    .content-banner-home {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 40px;
        z-index: 999;

        h5 {
            color: white;
        }

        text-align: center;
    }

    .btn-box {
        margin-top: 20px;
        border: 2px solid #252e48;
        background-color: #318dce;
        width: 14em;
        padding: 12px;
        border-radius: 14px;
        text-transform: uppercase;
        color: white;
        font-size: 0.8em;
    }
}

.banner-home img {
    width: 100%;
    vertical-align: top;
    height: 255px;
    object-fit: cover;
}

.banner-home:after {
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

.banner-home:hover:after {
    opacity: 1;
}

.overlay-bg-image {
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.4);
    display: none;
}

.p-vertical-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.p-vertical-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.padding-b-20 {
    padding-bottom: 20px;
}

.padding-l-5 {
    padding-left: 5px;
}

.margin-t-25 {
    margin-top: 25px;
}

.margin-t-35 {
    margin-top: 35px;
}

.padding-r-20 {
    padding-right: 20px;
}

.margin-t-80 {
    margin-top: 80px !important;
    ;
}

.ma-5 {
    margin: 5px;
}

.box-padding {
    h5 {
        padding-top: 10% !important;
    }
}

.hidden {
    display: none;
}

.texto-info {
    padding-bottom: 50px;
}

.main-conteudo {
    padding: 40px 0px 40px 0px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.modal-header {
    display: block !important;
}

.align-right {
    text-align: right !important;
}

.align-left {
    text-align: left !important;
}

.border-right {
    border-right: 1px solid rgba(25, 25, 25, 0.3);
}

.btn-default-blue {
    margin-top: 20px;
    border: 2px solid #252e48;
    background-color: #318dce;
    width: 16em;
    padding: 5px;
    border-radius: 12px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    line-height: 17px;
    font-size: 0.8em;
    height: 45px;

    &:hover {
        background-color: transparent;
        color: #318dce;
        border-color: #318dce;
        transition: background-color 150ms linear;
    }
}


.geral-content a {
    color: #0000EE !important;
}

.overlay-bg-image-banner {
    width: 100%;
    height: 255px;
    background-color: rgba(25, 25, 25, 0.4);
    display: none;
    margin-top: -14%;
}

.btn-default-grey {
    margin-top: 20px;
    border: 2px solid #868686;
    background-color: #d2d2d2;
    width: 16em;
    padding: 5px;
    border-radius: 12px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    line-height: 17px;
    font-size: 0.8em;
    height: 45px;
}

.btn-download {
    margin-top: 20px;
    border: 2px solid #44382e;
    background-color: #896957;
    width: 12em;
    padding: 5px;
    border-radius: 12px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    line-height: 17px;
    font-size: 0.9em;
    height: 45px;

    &:hover {
        background-color: transparent;
        color: #896957;
        border-color: #896957;
        transition: background-color 150ms linear;
    }

    .icon-download {
        padding-right: 10px;
        font-size: 16px;
    }
}

.btn-download-icon {
    margin-top: 2px;
    border: 2px solid #44382e;
    background-color: #896957;
    width: 60px;
    padding: 5px;
    border-radius: 12px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    line-height: 17px;
    font-size: 15px;
    height: 45px;

    &:hover {
        background-color: transparent;
        color: #896957;
        border-color: #896957;
        transition: background-color 150ms linear;
    }

    .icon-download {
        font-size: 25px;
    }
}

a {

    //text-decoration: none;
    //color: inherit;

    &:hover {
        color: inherit;
    }
}

p a {
    text-decoration: underline;
    color: blue;
}


body.contrast-active {
    background-color: black;

    header,
    footer {
        background-color: black !important;
    }

    p,
    strong,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    i,
    u,
    b {
        color: white !important;
    }

    a,
    button,
    .acessibilidade-menu li {
        color: yellow !important;
    }

    .box-info-item,
    .container-map,
    .container-linha-do-tempo,
    .overlay-footer,
    .content-footer {
        background-color: black
    }

    .logos-footer {
        background-color: white;
    }

    .btn-menu {
        background-color: black !important;
        border: 1px solid white !important;
    }

    .btn-pesquisa,
    .btn-outline-secondary {
        background: black !important;
    }

    .box-info-item {
        border: 1px solid white !important;
    }

    .btn-box,
    .btn-download-icon,
    .btn-download,
    .btn-default-blue {
        background-color: black !important;
        border-color: white !important;
        color: yellow !important;
    }

    img {
        filter: grayscale(100%);
    }

    .main-conteudo ul li {
        color: white;
    }

    .descricao-info-doenca,
    .title-info-doenca {
        border: 1px solid white !important;
        background-color: black !important;
    }


}

.container-main {

    @media screen and (min-width: $desk-screen) {
        padding: 0em 4em 0em 4em;

        .btn-curso1 {
            text-align: right;
        }

        .btn-curso2 {
            text-align: left;
        }

        .btn-download-home {
            margin-top: 80px;
        }

        .detalhe-btn-curso2 {
            text-align: left;
        }

        .btn-download-doc {
            margin-top: 80px !important;
        }

        .acessibilidade-menu {
            padding-top: 15px;

            li {
                list-style: none;
                font-size: 0.8em;
                color: #919191;
                cursor: pointer;

                &:hover {
                    color: #6f6e6e;
                    font-weight: bold;
                }

                a {
                    text-decoration: none !important;
                }
            }
        }
    }

    @media screen and (max-width: $mob-screen) {
        .conteudo {
            margin: 0px !important;
        }

        .btn-curso1,
        .btn-curso2 {
            text-align: center;
        }

        .box-download-btn {
            text-align: center;
        }

        .btn-download-doc {
            text-align: center;
        }

        .acessibilidade-menu {
            display: none;
        }
    }
}


.menu-mob {
    @media screen and (min-width: $desk-screen) {
        display: none;
    }

    @media screen and (max-width: $mob-screen) {
        display: block;

        .bandeira-mob {
            img {
                width: 35px;
            }

            p,
            a {
                font-size: 0.8em;
                padding: 0px 8px 0px 8px;
            }
        }
    }
}

.container-full-width {
    position: absolute;
    left: 0;
    height: inherit;
    width: 100%;
}

header {
    @media screen and (min-width: $desk-screen) {
        height: 230px;

        .menu-topo {
            padding: 0% 15% 0% 15%;
            margin: 0px;
        }

        .container-menu {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .conteudo-header {
            padding: 10px 0px 0px 40px;
        }

        .menu-site {
            display: block;
        }

        .bandeira {
            a {
                font-size: 0.8em;
            }
        }
    }

    @media screen and (max-width: $mob-screen) {
        height: 445px;

        .bandeira {
            display: none;

        }

        .menu-topo-item {
            &:first-child {
                border-left: none !important;
            }

            &:last-child {
                border-right: none !important;
            }
        }

        .menu-site {
            display: none;
        }

        .texto-header {
            text-align: center;
            padding: 15px 10px !important;
        }
    }

    width: 100%;
    background-color: #eaeaea;
    // padding: 0% 20% 0% 20%;

    .container {
        padding: 0px !important;
    }

    .menu-topo {
        height: 35px;
        box-shadow: 0 4px 3px -2px #ababab;

        img {
            width: 30px;
            height: 20px;
            object-fit: cover;
        }

        p {
            padding-left: 10px;
            font-size: 0.8rem;
            font-weight: bold;
        }

        .divider {
            border: 1px solid #cecece;
            margin: 0px 5px 0px 25px;
            height: 100%;
        }

        .menu-topo-item {
            cursor: pointer;
            padding: 0px 12px 0px 12px;
            border-right: 2px solid #cecece;

            a {
                font-size: 0.8rem;
                color: #919191;
                text-decoration: none !important;
                width: 100%;
                text-align: center;

                &:hover {
                    color: #6f6e6e;
                    font-weight: bold;
                }
            }

            &:first-child {
                border-left: 2px solid #cecece;
            }
        }
    }

    .content-header {
        height: 295px;
        width: 100%;

        @media screen and (min-width: $desk-screen) {
            .logo {
                padding-top: 5px;
            }
        }

        @media screen and (max-width: $mob-screen) {
            .social-icons {
                justify-content: center;
            }

            .pesquisa {
                padding-top: 15px;
            }
        }

        .social {
            padding: 6px 6px 0px 0px;
            text-align: center;
            flex-grow: 0;

            .imagem-social {
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 4px;
                border: 2px solid rgba(250, 250, 250, 1);
                cursor: pointer;
            }
        }

        .conteudo-header {

            .pesquisa {
                .input-group {
                    .input-pesquisa {
                        border-top: 2px solid $color-main;
                        border-left: 2px solid $color-main;
                        border-bottom: 2px solid $color-main;
                        border-right: 0px !important;
                        background-color: white;
                        border-radius: 4px 0px 0px 4px;
                        text-align: center;
                        height: 40px;
                        font-size: 14px;

                        &::-webkit-input-placeholder {
                            color: #acacac !important;
                            font-size: 14px;
                        }
                    }

                    .icon-input {
                        height: 40px;
                        padding: 2px;
                        background-color: white;
                        border-top: 2px solid $color-main;
                        border-right: 2px solid $color-main;
                        border-bottom: 2px solid $color-main;
                        border-radius: 0px 4px 4px 0px;

                        button {
                            border: none;
                            background-color: $color-main;
                            padding-top: 5px;
                            border-radius: 4px;
                            padding-left: 8px;
                            padding-right: 8px;

                            i {
                                color: white;
                            }

                            &:hover {
                                background-color: #91c1b5;
                            }
                        }

                    }
                }

                .btn-pesquisa {
                    border: 2px solid #537d72;
                    font-size: 0.8em;
                    height: 40px;
                    border-radius: 8px;
                    color: white;
                    padding: 0px 10px 0px 10px;
                    text-transform: uppercase;
                    background: -moz-linear-gradient(top, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(83, 125, 114, 1)), color-stop(29%, rgba(83, 125, 114, 1)), color-stop(49%, rgba(83, 125, 114, 1)), color-stop(58%, rgba(99, 143, 130, 1)), color-stop(100%, rgba(99, 143, 130, 1)));
                    background: -webkit-linear-gradient(top, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
                    background: -o-linear-gradient(top, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
                    background: -ms-linear-gradient(top, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
                    background: linear-gradient(to bottom, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#537d72', endColorstr='#638f82', GradientType=0);

                    &:hover {
                        background: transparent !important;
                        border-color: #537d72 !important;
                        color: #537d72 !important;
                    }
                }
            }

            .texto-header {
                padding-top: 20px;
                padding-left: 5px;
                font-size: 0.8em;
            }
        }

        .menu {
            height: 45px;
            padding-top: 5px;
            border-top: 1px solid rgba(25, 25, 25, 0.1);

            .col,
            .col-3 {
                padding: 0px 4px 0px 0px;
            }

            .btn-menu {
                border: 2px solid rgba(25, 25, 25, 0.1);
                width: 100%;
                height: 100%;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
                color: $color-white;
                border-radius: 4px;

                &:hover {
                    background-color: transparent !important;
                    border-color: #537d72 !important;
                    color: #537d72 !important;
                    transition: background-color 150ms linear;
                }
            }
        }
    }
}


footer {
    .overlay-footer {
        height: 35px;
        background-color: #e1e0e0;
    }

    .content-footer {
        @media screen and (min-width: $desk-screen) {
            padding: 3% 12% 0% 12%;
        }

        @media screen and (max-width: $mob-screen) {}

        min-height: 380px;
        height: auto;
        background-color: #c2c2c2;

        .menu-footer {
            .item-menu-footer {
                padding-top: 10px;

                i {
                    font-size: 8px;
                }

                a {
                    font-size: 0.9em;
                    padding-left: 6px;
                    font-weight: bold;
                    cursor: pointer;
                    color: $color-black;
                }
            }
        }

        .termos-de-uso {
            padding: 5% 0% 2% 0%;

            a {
                font-weight: bold;
                font-size: 0.8em;
            }
        }

        .endereco {
            border-top: 2px solid $color-black;
            border-bottom: 2px solid $color-black;

            p {
                font-size: 0.8em;

                span {
                    padding-left: 10px;
                }
            }
        }

        .logos-footer {
            padding-top: 2vw;

            img {
                // width: 65vw;
            }
        }
    }
}

.conteudo {
    margin: 0px -10px 0px -15px;
    padding: 15px 0px 0px 0px;


    .box-title-image {
        height: 255px;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
        // padding: 3em 1.4em 1.4em 1.4em;

        .btn-box {
            margin-top: 20px;
            border: 2px solid #252e48;
            background-color: #318dce;
            width: 14em;
            padding: 12px;
            border-radius: 14px;
            text-transform: uppercase;
            color: white;
            font-size: 0.8em;

            &:hover {
                background-color: transparent;
                color: #318dce;
                border-color: #318dce;
                transition: background-color 150ms linear;
            }
        }

        h5 {
            font-size: 1.5em;
            color: white;
            text-transform: none;
            padding-top: 55px;
        }
    }
}


// HOME
.box-info {
    padding-top: 20px;

    .box-info-item {
        height: 250px;
        background-color: #f6ecec;
        border-radius: 2px;
        padding: 2.7em;
        text-align: center;
        margin-bottom: 10px;

        h5 {
            color: #a84947;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            padding-bottom: 10px;
        }

        p {
            color: #505050;
            font-weight: bold;
            font-size: 18px;
        }
    }
}

.cursos-btn {
    padding-top: 40px;

    h5 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
    }
}

.categorias-box {
    padding-top: 50px;

    .categoria-box-item {
        height: 250px;
        background-size: cover;
        text-align: center;
        cursor: pointer;
        margin-right: 25px;
        margin-bottom: 10px;

        &:last-child {
            margin-right: 0px !important;
        }

        h5 {
            color: white;
            position: absolute;
            bottom: 0;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            font-size: 1.2em;
            padding: 2em 2em;
        }


    }
}

.agenda {
    div.zabuto_calendar .table tr.calendar-month-header td span {
        font-weight: bold !important;
        font-size: 22px !important;
        color: white;
    }

    div.zabuto_calendar .table tr.calendar-month-header td {
        background-color: #8b8ad0;
    }

    .iconCalendar {
        font-size: 30px !important;
    }
}

.mapa {
    margin-top: 50px;
    width: 100%;
    height: 880px;

    @media screen and (max-width: $desk-screen) {
        height: 800px;

        .highcharts-root {
            height: 600px !important;
        }
    }

    @media screen and (max-width: $mob-screen) {
        height: 850px;

        .highcharts-root {
            height: 300px !important;
        }

        .h-90 {
            height: 63% !important;
        }

        .legenda-mapa {
            padding-top: 20px !important;
        }
    }

    .container-map {
        padding: 50px 4% 0% 4%;
        background-color: #fffaf4;

        h5 {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
        }

        p {
            padding-top: 10px;
            font-size: 16px;
        }

        .legenda-mapa {
            h6 {
                font-weight: bold;
                color: #c59c6c;
            }
        }

        .internal-link.with-bullet:before,
        .legend-group:before {
            content: "\25CF";
            margin-right: 5px;
        }

        .legend-group-1:before {
            color: #dc4b93 !important;
        }

        .legend-group-2:before {
            color: #5c931b !important;
        }

        .legend-group-3:before {
            color: #106680 !important;
        }

        .legend-group-4:before {
            color: #a33071 !important;
        }

        .legend-group-5:before {
            color: #e32d09 !important;
        }
    }
}

.noticias {

    h5 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
    }

    .box-noticias {
        padding-top: 20px;

        .box-noticia-item {
            height: 250px;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 5px;
            cursor: pointer;
            margin-bottom: 10px;

            .noticia-title {
                position: absolute;
                bottom: 0;
                padding: 12px;
                height: 50%;
                width: 100%;
                background-color: rgba(25, 25, 25, 0.6);

                p {
                    font-weight: bold;
                    font-size: 1em;
                    color: white;
                }
            }

        }
    }
}

.agenda {
    padding-bottom: 25px;

    h5 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
    }

    p {
        font-size: 16px;
    }

    .box-agenda {
        padding-top: 30px;
        font-size: 16px;

        strong {
            padding-bottom: 10px;
            display: block;
            font-weight: bold;
        }
    }
}


.linha-do-tempo {
    width: 100%;
    height: 740px;

    .container-linha-do-tempo {
        padding: 50px 4% 0% 4%;
        background-color: #eaefed;

        h5 {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
        }

        p {
            padding-top: 10px;
            font-size: 16px;
        }
    }
}

.documentos {
    padding-top: 25px;

    .row {
        padding: 15px 0px;
    }

    h5 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        padding-top: 15px;
    }

    img {
        width: 100%;
        height: 180px;
        object-fit: cover;
    }
}



// HOME




// GESTOR
.gestor {}

// GESTOR


// ETSUS
.etsus {

    @media screen and (min-width: $desk-screen) {
        .box-login {
            margin-right: 0;
            margin-left: auto;
        }

        .box-cadastro {
            margin-right: auto;
            margin-left: 0;
        }
    }

    @media screen and (max-width: $mob-screen) {

        .box-login,
        .box-cadastro {
            margin: 0 auto;
            margin-top: 10px !important;
        }
    }

    .box-login {
        background: rgba(122, 153, 145, 1);
        background: -moz-linear-gradient(-45deg, rgba(122, 153, 145, 1) 0%, rgba(116, 153, 142, 1) 34%, rgba(83, 125, 114, 1) 58%, rgba(83, 125, 114, 1) 71%, rgba(83, 125, 114, 1) 100%);
        background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(122, 153, 145, 1)), color-stop(34%, rgba(116, 153, 142, 1)), color-stop(58%, rgba(83, 125, 114, 1)), color-stop(71%, rgba(83, 125, 114, 1)), color-stop(100%, rgba(83, 125, 114, 1)));
        background: -webkit-linear-gradient(-45deg, rgba(122, 153, 145, 1) 0%, rgba(116, 153, 142, 1) 34%, rgba(83, 125, 114, 1) 58%, rgba(83, 125, 114, 1) 71%, rgba(83, 125, 114, 1) 100%);
        background: -o-linear-gradient(-45deg, rgba(122, 153, 145, 1) 0%, rgba(116, 153, 142, 1) 34%, rgba(83, 125, 114, 1) 58%, rgba(83, 125, 114, 1) 71%, rgba(83, 125, 114, 1) 100%);
        background: -ms-linear-gradient(-45deg, rgba(122, 153, 145, 1) 0%, rgba(116, 153, 142, 1) 34%, rgba(83, 125, 114, 1) 58%, rgba(83, 125, 114, 1) 71%, rgba(83, 125, 114, 1) 100%);
        background: linear-gradient(135deg, rgba(122, 153, 145, 1) 0%, rgba(116, 153, 142, 1) 34%, rgba(83, 125, 114, 1) 58%, rgba(83, 125, 114, 1) 71%, rgba(83, 125, 114, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7a9991', endColorstr='#537d72', GradientType=1);
        border: 2px solid #394f44;
        border-radius: 18px;
        width: 65%;

        height: 260px;

        .form-group {
            padding: 10px 16px 0px 16px;

            label {
                color: white;
                padding-left: 10px;
                margin-bottom: 3px;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: bold;
            }

            input {
                border-radius: 16px;
                border: none;
                height: 45px;
            }
        }

        .btn-login {
            background-color: #8eaa9b;
            height: 45px;
            border-radius: 16px;
            width: 92%;
            margin-left: 16px;
            color: white;
            border: none;
            border: 2px solid #394f44;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .box-cadastro {
        padding: 56px 0px 56px 56px;
        border-radius: 18px;
        width: 60%;
        height: 260px;
        background-color: #3c5b4a;

        h5 {
            padding-top: 0px !important;
            color: white;
            font-size: 25px;
        }

        .btn-cadastro {
            margin-top: 20px;
            border-radius: 16px;
            height: 45px;
            border: none;
            color: white;
            background-color: #8ca99a;
            width: 80%;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}

// ETSUS


// APERFEIÃOAMENTO
.detalhes-curso {

    h5 {
        padding-top: 20px;
        font-size: 20px;
        padding-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }


    ul {
        list-style: none;
        padding-left: 10px;

        li::before {
            content: "â¢ ";
            color: #3199ce;
        }
    }

}

// APERFEIÃOAMENTO



// APRESENTAÃÃO
.apresentacao {

    h5 {
        padding-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
    }
}

// APRESENTAÃÃO


// ALUNOS
.alunos {}

// ALUNOS



// CURSOS
.cursos {

    h5 {
        text-transform: uppercase;
    }

    .cursos-lista {
        margin: 0 auto;
        width: 85%;

        .item-curso-lista {
            height: 250px;
            background-repeat: no-repeat;
            background-size: 100%;
            cursor: pointer;

            a {
                text-decoration: none;
                width: 100%;
                height: 100%;
                position: absolute;
            }

            h5 {
                text-align: center;
                margin-top: 165px;
                color: white;
            }
        }
    }
}

// CURSOS


// DOENÃAS
.doencas {

    .doenca-lista {
        width: 25%;
        margin: 0 auto;
    }

    .doenca-lista ul li {
        cursor: pointer;
        text-align: left;
    }

    .doenca-lista ul li.active {
        text-decoration: underline;
    }

    .info-doenca {
        display: none;
        border-radius: 6px;
        margin: 0 auto;

        @media screen and (min-width: $desk-screen) {
            width: 60%;
        }

        @media screen and (max-width: $mob-screen) {
            width: 330px;
        }

        .title-info-doenca {
            text-align: center;
            border-radius: 6px 6px 6px 6px;

            h5 {
                color: white;
                padding: 10px 0px 10px 0px;
                text-transform: uppercase;
                font-size: 16px;
            }
        }

        .descricao-info-doenca {
            background-color: #ebeef0;
            border-radius: 0px 0px 6px 6px;
            padding: 20px;
        }
    }
}

// DOENÃAS


// DOCUMENTOS NORTEADORES
.documentos-norteadores {

    h5 {
        text-transform: uppercase;
    }

    img {
        object-fit: cover;
    }

    .row {
        padding-top: 15px;

        img {
            width: 100%;
            height: 230px;
        }
    }

}

// DOCUMENTOS NORTEADORES


// DETALHES NOTICIA
.detalhes-noticia {
    min-height: 335px;
    height: auto;

    .thumb-noticia {
        float: left;
        margin: 0px 25px 20px 0px;
        width: 300px;
        height: 250px;
        object-fit: cover;
    }
}

// DETALHES NOTICIA


// COMUNICACAO
.comunicacao {

    h5 {
        text-transform: uppercase;
    }

    strong {
        font-size: 1.2em;
    }

    .img-comunicacao {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
}

// COMUNICACAO



// ESPACO DE RELACIONAMENTO

.espaco-relacionamento {
    .form-espaco-relacionamento {
        width: 45%;

        @media screen and (min-width: $desk-screen) {
            width: 45%;
        }

        @media screen and (max-width: $mob-screen) {
            width: 100%;
        }

        .checkboxs {
            margin-left: 0px;
            padding: 20px 0px 20px 0px;
        }

        h5 {
            background-color: #c9e2dc;
            padding: 8px;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .form-control {
            margin-bottom: 25px;
            border-color: #79b7a8;
            font-size: 15px;
        }

        .form-group {
            label {
                text-transform: uppercase;
                font-size: 15px;
            }

            .select2-container--default .select2-selection--single {
                border-color: #79b7a8 !important;
            }
        }

        .form-file {
            input {
                border: 1px solid #79b7a8;
                border-radius: 4px;
            }
        }

        .submit-btn {
            background-color: #79b7a8;
            border: 2px solid #79b7a8;
            color: white;
            padding: 6px;
            border-radius: 6px;
            width: 25%;

            &:hover {
                background-color: transparent;
                color: #79b7a8;
                border-color: #79b7a8;
                transition: background-color 150ms linear;
            }
        }
    }
}

// ESPACO DE RELACIONAMENTO



// ACESSIBILIDADE
.acessibilidade {
    h5 {
        padding-top: 20px;
        text-transform: uppercase;
    }

    p {
        padding-bottom: 20px;
    }
}

// ACESSIBILIDADE





// DETALHES MAPA
.detalhe-mapa {
    h5 {
        padding-top: 20px;
        text-transform: uppercase;
    }

    .lista-info-mapa {
        list-style: none;
        padding: 20px 0px 20px 0px;
    }

    .bg-group-1 {
        background-color: #dc4b93;
    }

    .bg-group-2 {
        background-color: #5c931b;
    }

    .bg-group-3 {
        background-color: #106680;
    }

    .bg-group-4 {
        background-color: #a33071;
    }

    .bg-group-5 {
        background-color: #e32d09;
    }
}

// DETALHES MAPA





// BUSCA
.resultado-busca {

    .pesquisa {
        .input-group {
            .input-pesquisa {
                border-top: 2px solid $color-main;
                border-left: 2px solid $color-main;
                border-bottom: 2px solid $color-main;
                border-right: 0px !important;
                background-color: white;
                border-radius: 4px 0px 0px 4px;
                text-align: center;
                height: 40px;
                font-size: 14px;

                &::-webkit-input-placeholder {
                    color: #acacac !important;
                    font-size: 14px;
                }
            }

            .icon-input {
                height: 40px;
                padding: 2px;
                background-color: white;
                border-top: 2px solid $color-main;
                border-right: 2px solid $color-main;
                border-bottom: 2px solid $color-main;
                border-radius: 0px 4px 4px 0px;

                button {
                    border: none;
                    background-color: $color-main;
                    padding-top: 5px;
                    border-radius: 4px;
                    padding-left: 8px;
                    padding-right: 8px;

                    i {
                        color: white;
                    }

                    &:hover {
                        background-color: #91c1b5;
                    }
                }

            }
        }

        .btn-pesquisa {
            font-size: 0.8em;
            height: 40px;
            border-radius: 8px;
            color: white;
            padding: 0px 10px 0px 10px;
            text-transform: uppercase;
            background: -moz-linear-gradient(top, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(83, 125, 114, 1)), color-stop(29%, rgba(83, 125, 114, 1)), color-stop(49%, rgba(83, 125, 114, 1)), color-stop(58%, rgba(99, 143, 130, 1)), color-stop(100%, rgba(99, 143, 130, 1)));
            background: -webkit-linear-gradient(top, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
            background: -o-linear-gradient(top, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
            background: -ms-linear-gradient(top, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
            background: linear-gradient(to bottom, rgba(83, 125, 114, 1) 0%, rgba(83, 125, 114, 1) 29%, rgba(83, 125, 114, 1) 49%, rgba(99, 143, 130, 1) 58%, rgba(99, 143, 130, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#537d72', endColorstr='#638f82', GradientType=0);

        }
    }

    .busca-main {
        border-bottom: 1px solid rgba(25, 25, 25, 0.1);
        padding: 30px 0px 30px 0px !important;

        .numero-search {
            border-right: 1px dashed rgba(25, 25, 25, 0.1);
            flex: 0 !important;
            width: 45px;

            h5 {
                font-size: 1rem;
                text-decoration: none !important;
            }
        }

        .descricao-busca {
            h5 {
                color: #2c66ce !important;
            }


        }
    }
}

// BUSCA

// FORMULÃRIO RECURSO

.formulario-recurso {
    .form-formulario-recursos {
        width: 45%;

        .submit-btn {
            background-color: #79b7a8;
            border: 2px solid #79b7a8;
            color: white;
            padding: 6px;
            border-radius: 6px;
            width: 25%;

            &:hover {
                background-color: transparent;
                color: #79b7a8;
                border-color: #79b7a8;
                transition: background-color 150ms linear;
            }
        }
    }
}

// FORMULÃRIO RECURSO
